<template>
    <el-dialog title="排班" :visible="dialogVisible" width="60%" class="pp" @close="close" :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
            <el-row>
                <el-col :span="12" class="tree">
                    <el-tree
                            ref="tree"
                            :data="data"
                            show-checkbox
                            node-key="id"
                            :default-expanded-keys="form.doctors"
                            :default-checked-keys="form.doctors"
                            @check-change="handleCheckChange"
                            :props="defaultProps">
                    </el-tree>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="值班日期" prop="dates">
                        <selectDates v-model="form.dates"/>
                    </el-form-item>
                    <el-form-item label="时间段" prop="times">
                        <time-inputs v-model="form.times"></time-inputs>
                    </el-form-item>
                    <el-form-item label="备注" prop="desc">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
    </el-dialog>
</template>

<script>
    import SelectDates from "./widgets/selectDates"

    export default {
        components: {SelectDates},
        props: {
            forms:{
                default: ''
            },
            dialogVisible: {
                default: false
            },
            hospital_id:{
                default: ''
            }
        },
        data() {
            return {
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                form: {
                    doctors:[]
                },
                rules: {}
            };
        },
        methods: {
            handleCheckChange(data, checked, indeterminate) {
                console.log(data, checked, indeterminate);
            },
            async submit() {
                this.$refs.tree.getCheckedNodes().forEach(e => {
                    if (e.type === 'doctor') this.form.doctors.push(e.id)
                })
                console.log(this.form)
                const resp = await this.$http.post('/mingde/worksheet/dates/', this.form)
                console.log(resp)
                this.$emit('close')
                this.$emit('update')
            },
            close() {
                this.$emit('close')
            },
            async getData() {
                const resp = await this.$http.get('/mingde/worksheet/doctor/tree?hospital_id='+ this.hospital_id)
                //console.log(resp)
                this.data = resp.data.data
            }
        },
        watch: {
            hospital_id: {
                immediate: true,
                handler: function () {
                    this.getData()
                },
            },
        },
        mounted() {
            this.getData()
            //console.log(123131)
        }
    };
</script>

<style scoped>
    .tree {
        height:33vh;
        overflow:auto;
    }
    .pp /deep/ .el-dialog{min-width:790px;}
</style>