<template>
    <el-dialog title="日历排班" :visible="dialogVisible" width="80%" @close="close" :close-on-click-modal="false">
        <div class="page-main">
            <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%">
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                    <template slot-scope="scope">
                        <span v-if="idx==4"><div class="time" v-for="t in scope.row.times" :key="t.ID">{{t.time}}({{t.count}})</div></span>
                        <span v-else>{{scope.row[v.field]}}</span>
                    </template>

                </el-table-column>
                <el-table-column align="center" label="操作" width="180" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" size="small" type="warning"
                                   icon="el-icon-edit"></el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.doctor}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize"
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            dialogVisible: {
                default: false
            },
            hospital_id:{
                default: ''
            },
            dates:{
                default: ''
            }
        },
        data() {
            return {
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                form: {
                    doctors: []
                },
                rules: {},
                loading: false,
                table: {
                    columns: [
                        {title: '院区', field: 'hospital_id', width: '160'},
                        {title: '类型', field: 'type_id', width: '100'},
                        {title: '科室', field: 'department_id', width: '100'},
                        {title: '医生', field: 'doctor_id', width: '220'},
                        {title: '排班', field: 'times', width: '330',},
                        {title: '备注', field: 'desc', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 5
                },
                search: {},
            };
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async handleDel(index, row) {
                this.$refs[`popover-${index}`].doClose()
                const resp = await this.$http.delete(`/mingde/worksheet/datesheet/${row.id}`)
                if (resp.data.code == 200)
                    this.$message.success("删除成功")
                else
                    this.$message.error(resp.data.msg)
                this.getData()
            },
            handleUpdate(row) {
                console.log(row)
                this.$emit('return-value', row)
                this.$emit('change', true)
                //this.$router.push(`/main/worksheet/datesheet/form?id=${row.id}`)
            },
            handleCheckChange(data, checked, indeterminate) {
                console.log(data, checked, indeterminate);
            },
            async getData() {
                //console.log(this.dates)
                //console.log(this.hospital_id)
                this.loading = true
                let params = this.search
                params['page'] = this.table.page
                params['size'] = this.table.pageSize
                //const resp = await this.$http.get('/mingde/worksheet/', {params: params})
                const resp = await this.$http.get(`/mingde/worksheet/datesheet/?hospital_id=${this.hospital_id}&date=${this.dates}`)
                //console.log(resp)
                this.table.data = resp.data.data|| []
                this.table.total = resp.data.data.length
                this.loading = false
            },
            close() {
                this.$emit('close')
            },
        },
        watch: {
            hospital_id: {
                immediate: true,
                handler: function () {
                    this.getData()
                },
            },
            dates: {
                immediate: true,
                handler: function () {
                    this.getData()
                },
            },
        },
        mounted() {
            this.getData()
        }
    };
</script>

<style scoped>
    .page-footer{
        text-align:center;
        padding-top:20px;
    }
    .page-main{height:calc(100% - 150px);}
    .time{display:inline-block;margin:0 5px;}
    /deep/ .el-dialog{height:70vh}
    /deep/ .el-dialog__body{padding-top:0;padding-bottom:20px;height:100%;}
</style>