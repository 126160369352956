<template>
    <div>
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>门诊排班</el-breadcrumb-item>
            <el-breadcrumb-item>日历排班</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row>
            <el-col :span="14">
                <el-form size="small" :inline="true">
                    <el-form-item>
                        <select-hospital v-model="form.hospital_id" @change="modify"/>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker v-model="form.date" type="month" size="small" placeholder="选择月"
                                        value-format="yyyy-MM-dd" @change="getData"/>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="10" style="text-align: right">
                <el-button type="primary" size="small" @click="newPaiban">新建排班</el-button>
            </el-col>
        </el-row>
        <div class="calendar">
            <div v-for="h in calendar.header" :key="h.ID" class="box header">{{h}}</div>
            <div v-for="(value, key, index) in calendar.data" :key="index" class="box" @click="getListByDate(key,value)">
                <div :class="getClass(key)">
                    {{key|getDay}}
                </div>
                <div v-for="(v, d, j) in value" :key="j">
                    {{'出诊'}}({{v}})
                </div>
            </div>
        </div>
        <dialog-form :forms="forms" :dialogVisible="dialogVisible" @close="dialogVisible = false" :hospital_id="form.hospital_id"
                     @update="getData"/>

        <dialog-forms @return-value="getForm" @change="costChange($event)" :dialogVisible="dialogVisibles" @close="dialogVisibles = false" :hospital_id="form.hospital_id" :dates="form.dates"/>
    </div>
</template>
<script>
    import dialogForm from "./dialogForm";
    import dialogForms from "./dialogForms";
    export default {
        components: {
            dialogForm,
            dialogForms
        },
        data() {
            let now = new Date()
            let today = now.format('yyyy-MM-dd')
            return {
                forms:{},
                form: {
                    date: today,
                    hospital_id:1,
                    dates:today
                },
                dialogVisibles:false,
                dialogVisible: false,
                bianji:'',
                calendar: {
                    header: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                    data: []
                }
            }
        },
        filters: {
            getDay(date) {
                let d = date.split("-")
                return d[2]
            }
        },
        methods: {
            getForm (result) {
                this.forms = result
                console.log(this.forms)
            },
            // 事件处理函数
            async costChange(value) {
                this.dialogVisible = value
            },
            modify(){
                if(this.form.hospital_id!=''||this.form.hospital_id!=null){
                    this.getData()
                }
            },
            newPaiban(){
                console.log(this.form)
                if(this.form.hospital_id==undefined||this.form.hospital_id==''){
                    this.$message('请先选择院区，再进行排班');
                }else{
                    this.dialogVisible=true
                    this.hospital_id = this.form.hospital_id
                }

            },
            getClass(key) {
                if (key.slice(0, 7) === this.form.date.slice(0, 7)) {
                    return 'day'
                }
                return 'day light'
            },
            async getData() {
                const resp = await this.$http.get('/mingde/worksheet/calendar', {params: this.form})
                this.calendar.data = resp.data.data
                console.log(resp)
            },
            dateChange(e) {
                console.log(e)
                this.getData()
            },
            close() {
                this.$emit('close')
            },
            async getListByDate(date,value) {
                if(value==null){
                    this.$message({
                        message:'当日无已有排班，请新建排班。',
                        type:'warning'
                    })
                }else{
                    this.dialogVisibles = true
                    const resp = await this.$http.get(`/mingde/worksheet/datesheet/?hospital_id=${this.form.hospital_id}&date=${date}`)
                    this.form.dates = date
                    console.log(resp.data)
                }

            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
        color: #333;
    }

    .calendar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;

        .header {
            font-weight: bold;
            font-size: 110%;
            line-height: 50px;
            min-height: 50px !important;
        }

        .box {
            width: 14.28%;
            min-height: 100px;
            /*margin-bottom: 2px;*/
            border-bottom: 1px solid #ccc;
            .day {
                padding-top:10px;
                font-size: 150%;
            }

            .light {
                color: #aaa;
            }
        }
    }
</style>